<template>
  <th class="table-header-title">
    <slot></slot>
  </th>
</template>

<script>
export default {};
</script>

<style scoped>
.table-header-title {
  padding: 16px;
  font-family: "Graphik";
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}
</style>
