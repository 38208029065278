<template>
  <div>
    <table class="lunch-list lunch-list-persons">
      <thead>
        <tr>
          <th></th>
          <lunch-table-header
            v-for="date in allWeekDates.currentWeek.weekDates"
            :key="date.date.toString()"
            >{{ date.date | dateHeader }}</lunch-table-header
          >
          <lunch-table-spacer />
          <lunch-table-header
            v-for="date in allWeekDates.week2.weekDates"
            :key="date.date.toString()"
            >{{ date.date | dateHeader }}</lunch-table-header
          >
          <lunch-table-spacer />
          <lunch-table-header
            v-for="date in allWeekDates.week3.weekDates"
            :key="date.date.toString()"
            >{{ date.date | dateHeader }}</lunch-table-header
          >
          <lunch-table-spacer v-show="showWeek4" />
          <lunch-table-header
            v-show="showWeek4"
            v-for="date in allWeekDates.week4.weekDates"
            :key="date.date.toString()"
            >{{ date.date | dateHeader }}</lunch-table-header
          >
        </tr>
      </thead>
      <tbody>
        <lunch-list-row
          v-for="person in employees"
          :key="person.email"
          :person="person"
          :dates="allWeekDates"
          @showFloater="setFloater"
        />
        <lunch-list-aggregate-row
          :dates="allWeekDates"
          type="Total"
          :lunchTotals="lunchTotals"
        />
      </tbody>
    </table>
    <floater :showFloater="showFloater">
      Press
      <strong>ENTER</strong> to save. Press <strong>ESC</strong> to cancel.
    </floater>
  </div>
</template>

<script>
import format from "date-fns/format";
import Floater from "@/components/Lunch/Floater";
import LunchListAggregateRow from "@/components/Lunch/LunchListAggregateRow";
import LunchListRow from "@/components/Lunch/LunchListRow";
import LunchTableHeader from "@/components/Lunch/LunchTableHeader";
import LunchTableSpacer from "@/components/Lunch/LunchTableSpacer";

export default {
  name: "LunchList",
  components: {
    Floater,
    LunchListAggregateRow,
    LunchListRow,
    LunchTableHeader,
    LunchTableSpacer
  },

  props: {
    allWeekDates: {
      type: Object,
      required: true
    },
    employees: {
      type: Object,
      required: true
    },
    lunchTotals: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showFloater: false
    };
  },

  computed: {
    showWeek4() {
      return parseInt(this.weekDay) >= 4 || parseInt(this.weekDay) === 0;
    }
  },

  methods: {
    setFloater(bool) {
      this.showFloater = bool;
    }
  },
  filters: {
    dateHeader(value) {
      return value ? format(value, "EEE dd/MM") : "";
    }
  }
};
</script>

<style>
input,
select,
textarea {
  -webkit-appearance: none;
  font-family: "Graphik", serif;
}

.lunch-list {
  width: 100%;
  border-collapse: collapse;
}

.lunch__total,
.lunch__extra-title {
  padding: 16px;
  font-family: "Graphik";
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}

.lunch-list__date,
.lunch__total,
.lunch__amount,
.lunch__extra {
  border: 2px solid #fff;
  padding: 0 8px;
  text-align: center;
}

.lunch-list__date {
  padding: 0 12px;
  text-align: center;
  font-size: 14px;
  background-color: #b6b2ae;
  color: #000;
  font-weight: 600;
}
.lunch-list__date > span {
  display: block;
}
.lunch__extra {
  padding: 0;
}

.lunch__total {
  font-size: 16px;
}
.lunch__amount {
  padding: 0;
  position: relative;
}

.lunch-list__row.today,
.lunch-list__row.today .lunch-list__date {
  background-color: #b3d7e4;
}

.lunch-menu-top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0 20px 40px 0;
}

.lunch-menu-top__toggle {
  display: block;
  margin: 0 32px;
  color: #279fad;
}

.lunch-menu-top__refresh {
  display: block;
  width: auto;
  padding: 16px 24px;
  border: 4px solid #26c6da;
  background-color: #26c6da;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-family: "Graphik";
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
}
.lunch-menu-top__refresh:hover {
  background-color: #fff;
  color: #26c6da;
}

.notes__day {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 20px 40px 20px;
}

.note {
  width: 280px;
  margin: -2px 0 0 -2px;
  padding: 16px 16px 0 16px;
  background-color: #fff;
  border: 1px solid #d3d4d9;
}
.note.today {
  background-color: #f4f5f9;
}
.note__top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px 0;
}
.note__date {
  text-align: center;
  color: #9d9fa7;
}
.note__date > span {
  display: block;
  padding-top: 4px;
  color: #46485a;
  font-size: 20px;
  font-weight: bold;
}
.note__amount {
  color: #46485a;
  font-size: 32px;
  font-weight: bold;
}

.note__extra {
  margin: 0 -16px;
  border-top: 1px solid #eaebef;
  font-size: 14px;
}
.note__input {
  width: 100%;
  height: 50px;
  padding: 16px;
  background-color: transparent;
  resize: none;
  font-size: 14px;
  line-height: 1.3;
}

.note__persons {
  margin: 0 -16px;
  font-size: 14px;
  color: #494b4f;
}
.note__person {
  padding: 16px 16px 16px 16px;
}
.note__person:first-child {
  border-top: 1px solid #eaebef;
}
.note__name {
  padding: 0 0 3px 0;
  font-size: 12px;
  font-weight: bold;
  color: #9d9fa7;
}
</style>
