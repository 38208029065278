<template>
  <div class="notes__day">
    <lunch-notes-week-day
      v-for="(date, index) in week.weekDates"
      :key="index"
      :date="date"
      :amount="lunchTotals[date.weekday ? date.weekday : 'Monday']"
      :notes="lunchRecordWeekNotes"
      :extrasRecord="extrasRecord"
      @setFloater="setFloater"
    />
  </div>
</template>

<script>
import LunchNotesWeekDay from "@/components/Lunch/LunchNotesWeekDay";
export default {
  components: {
    LunchNotesWeekDay
  },

  props: {
    week: {
      type: Object,
      required: true
    },
    lunchTotals: {
      type: Object,
      required: true
    }
  },

  computed: {
    extrasRecord() {
      let record = this.lunchTotals.records.find(rec => rec.user.includes("extras"));
      if (record) {
        return record;
      } else
        return {
          weekNotes: {
            Monday: "",
            Tuesday: "",
            Wednesday: "",
            Thursday: "",
            Friday: ""
          }
        };
    },

    lunchRecordWeekNotes() {
      let weekNotes = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: []
      };

      this.lunchTotals.records.forEach(record => {
        const week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

        week.forEach(day => {
          if (record.weekNotes[day] !== "" && this.week.weekNumber === record.weekNumber) {
            weekNotes[day].push({
              name: record.user,
              note: record.weekNotes[day]
            });
          }
        });
      });

      return weekNotes;
    }
  },

  methods: {
    setFloater(value) {
      this.$emit("setFloater", value);
    }
  }
};
</script>

<style>
.notes__day {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 20px 40px 20px;
}
</style>
