<template>
  <tr class="lunch-list__row lunch-list__row--mon">
    <td class="lunch-list__date">{{ type }}</td>
    <!-- Current Week -->
    <lunch-list-aggregate-cell
      v-for="date in dates.currentWeek.weekDates"
      :key="date.date.toString()"
      :aggregatedData="lunchTotals.currentWeek"
      :date="date"
      :type="type"
    />
    <td class="lunch-list__row-spacing"></td>
    <!-- Week 2 -->
    <lunch-list-aggregate-cell
      v-for="date in dates.week2.weekDates"
      :key="date.date.toString()"
      :aggregatedData="lunchTotals.week2"
      :date="date"
      :type="type"
    />
    <td class="lunch-list__row-spacing"></td>
    <!-- Week 3 -->
    <lunch-list-aggregate-cell
      v-for="date in dates.week3.weekDates"
      :key="date.date.toString()"
      :aggregatedData="lunchTotals.week3"
      :date="date"
      :type="type"
    />
    <!-- Week 4 -->
    <td class="lunch-list__row-spacing" v-show="showWeek4"></td>
    <lunch-list-aggregate-cell
      v-show="showWeek4"
      v-for="date in dates.week4.weekDates"
      :key="date.date.toString()"
      :aggregatedData="lunchTotals.week4"
      :date="date"
      :type="type"
    />
  </tr>
</template>

<script>
import LunchListAggregateCell from "@/components/Lunch/LunchListAggregateCell";

export default {
  components: {
    LunchListAggregateCell
  },

  props: {
    type: {
      type: String,
      required: true
    },
    dates: {
      type: Object,
      required: true
    },
    lunchTotals: {
      type: Object,
      required: true
    }
  },

  computed: {
    showWeek4() {
      if (parseInt(this.weekDay) >= 4 || parseInt(this.weekDay) === 0) return true;
      else return false;
    }
  }
};
</script>

<style></style>
