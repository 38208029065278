<template>
  <div class="floater floater-notes" :class="{'floater--active': showFloater}">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    showFloater: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style>
.floater {
  display: inline-block;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  transform: translateX(calc(100% + 20px));
  transition: 0.2s ease-in-out;
}
.floater--active {
  transform: translateX(0);
}
</style>
