<template>
  <div class="note" :class="{ today: isToday }">
    <div class="note__top">
      <div class="note__date">
        {{ weekday }}
        <span>{{ date.date | dateFormat }}</span>
      </div>
      <div class="note__amount">{{ amount }}</div>
    </div>
    <div class="note__extra">
      <textarea
        class="note__input"
        placeholder="No notes for today"
        :value="extrasRecord.weekNotes[date.weekday]"
        ref="input"
        @input="handleInput"
        @keyup="handleSave"
        @focus="$emit('setFloater', true)"
        @blur="$emit('setFloater', false)"
      ></textarea>
    </div>
    <div class="note__persons">
      <lunch-notes-week-day-person
        v-for="(person, index) in notesCurrentDay"
        :key="index"
        :person="person"
      />
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import LunchNotesWeekDayPerson from "@/components/Lunch/LunchNotesWeekDayPerson";
import { createLunchRecord, saveLunchRecord } from "@/api";
import { getISOWeek } from "date-fns";

export default {
  name: "LunchNotesWeekDay",
  components: {
    LunchNotesWeekDayPerson
  },

  props: {
    date: {
      type: Object,
      required: true
    },

    notes: {
      type: Object,
      required: true
    },
    amount: Number,
    extrasRecord: Object
  },

  data() {
    return {
      extrasNoteInput: ""
    };
  },

  computed: {
    isToday() {
      return !!isToday(this.date.date);
    },

    weekday() {
      return format(this.date.date, "EEEE");
    },

    notesCurrentDay() {
      return this.notes[this.weekday].filter(
        note => !note.name.includes("extras")
      );
    }
  },

  methods: {
    handleInput(e) {
      this.extrasNoteInput = e.target.value;
    },

    async handleSave(e) {
      if (e.key === "s" && e.ctrlKey) {
        let rec = { ...this.extrasRecord };

        // We check that lunch record exists already
        if (!rec.id) {
          let office = this.$route.params.office;
          let weekNumber = getISOWeek(this.date.date);
          let year = new Date(this.date.date).getFullYear();
          rec = createLunchRecord({
            office,
            user: `extras-${office}`,
            weekNumber,
            year
          });
        }

        rec.weekNotes[this.date.weekday] = this.extrasNoteInput;
        await saveLunchRecord(rec);
        this.$refs.input.blur();
      } else if (e.key === "Escape") {
        this.$refs.input.blur();
      }
    }
  },

  filters: {
    dateFormat(value) {
      if (!value) return "";
      return format(value, "dd/MM");
    }
  }
};
</script>

<style>
.note {
  width: 280px;
  margin: -2px 0 0 -2px;
  padding: 16px 16px 0 16px;
  background-color: #fff;
  border: 1px solid #d3d4d9;
}
.note.today {
  background-color: #f4f5f9;
}
.note__top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px 0;
}
.note__date {
  text-align: center;
  color: #9d9fa7;
}
.note__date > span {
  display: block;
  padding-top: 4px;
  color: #46485a;
  font-size: 20px;
  font-weight: bold;
}
.note__amount {
  color: #46485a;
  font-size: 32px;
  font-weight: bold;
}

.note__extra {
  margin: 0 -16px;
  border-top: 1px solid #eaebef;
  font-size: 14px;
}
.note__input {
  width: 100%;
  height: 50px;
  padding: 16px;
  background-color: transparent;
  resize: none;
  font-size: 14px;
  line-height: 1.3;
}

.note__persons {
  margin: 0 -16px;
  font-size: 16px;
  line-height: 1.3;
  color: #494b4f;
}
</style>
