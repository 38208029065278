<template>
  <div class="notes">
    <lunch-notes-week v-for="(week, index) in allWeekDates" :key="index" :week="week" :lunchTotals="getWeekTotals(week.weekNumber)" @setFloater="setFloater" />
    <floater :showFloater="showFloater">
      Press
      <strong>CTRL + S</strong> to save. Press <strong>ESC</strong> to cancel.
    </floater>
  </div>
</template>

<script>
import Floater from "@/components/Lunch/Floater";
import LunchNotesWeek from "@/components/Lunch/LunchNotesWeek";
export default {
  name: "LunchNotes",
  components: {
    Floater,
    LunchNotesWeek
  },

  props: {
    allWeekDates: {
      type: Object,
      required: true
    },

    lunchTotals: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showFloater: false
    };
  },

  methods: {
    getWeekTotals(weekNumber) {
      let correct = null;
      for (let week of Object.keys(this.lunchTotals)) {
        if (this.lunchTotals[week].weekNumber === weekNumber) {
          correct = this.lunchTotals[week];
        }
      }
      return correct;
    },
    setFloater(bool) {
      this.showFloater = bool;
    }
  }
};
</script>

<style></style>
