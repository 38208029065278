<template>
  <td class="lunch__total">{{total}}</td>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    aggregatedData: {
      type: Object,
      default: () => {}
    },
    date: {
      type: Object,
      required: true
    }
  },

  computed: {
    total() {
      if (this.aggregatedData[this.date.weekday]) {
        return this.aggregatedData[this.date.weekday];
      } else return "N/A";
    },
    test() {
      return "hei";
    }
  }
};
</script>

<style>
</style>
