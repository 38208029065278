<template>
  <tr>
    <td class="lunch-list__date">{{ displayName }}</td>
    <!-- Week 1 -->
    <lunch-list-row-cell
      v-for="date in dates.currentWeek.weekDates"
      :key="person.email + date.date.toString()"
      :date="date"
      :week="dates.currentWeek"
      :lunchRecord="setCorrectRecord(date)"
      :person="person"
      @showFloater="showFloater"
    />
    <td class="lunch-list__row-spacing"></td>

    <!-- Week 2 -->
    <lunch-list-row-cell
      v-for="date in dates.week2.weekDates"
      :key="person.email + date.date.toString()"
      :date="date"
      :week="dates.week2"
      :lunchRecord="setCorrectRecord(date)"
      :person="person"
      @showFloater="showFloater"
    />
    <td class="lunch-list__row-spacing"></td>

    <!-- Week 3 -->
    <lunch-list-row-cell
      v-for="date in dates.week3.weekDates"
      :key="person.email + date.date.toString()"
      :date="date"
      :week="dates.week3"
      :lunchRecord="setCorrectRecord(date)"
      :person="person"
      @showFloater="showFloater"
    />

    <!-- Week 4 (if weekday is thursday - sunday) -->
    <td class="lunch-list__row-spacing" v-show="showWeek4"></td>
    <lunch-list-row-cell
      v-show="showWeek4"
      v-for="date in dates.week4.weekDates"
      :key="person.email + date.date.toString()"
      :date="date"
      :week="dates.week4"
      :lunchRecord="setCorrectRecord(date)"
      :person="person"
      @showFloater="showFloater"
    />
  </tr>
</template>

<script>
import getISOWeek from "date-fns/getISOWeek";
import LunchListRowCell from "./LunchListRowCell";

export default {
  components: {
    LunchListRowCell
  },
  props: {
    person: {
      type: Object,
      required: true
    },
    dates: {
      type: Object,
      required: true
    }
  },

  methods: {
    showFloater(bool) {
      this.$emit("showFloater", bool);
    },

    setCorrectRecord(date) {
      let correctWeek = getISOWeek(date.date);
      let lunchRecord = this.person.lunchRecords.find(
        el => el.weekNumber === correctWeek
      );
      if (lunchRecord) {
        return lunchRecord;
      } else return {};
    }
  },

  computed: {
    displayName() {
      return `${this.person.firstName} ${this.person.lastName}`;
    },

    showWeek4() {
      return parseInt(this.weekDay) >= 4 || parseInt(this.weekDay) === 0;
    }
  }
};
</script>

<style>
.lunch-list__row-spacing {
  padding: 10px 8px;
  background: #fff;
}
</style>
