<template>
  <div class="note__person">
    <div class="note__name">{{person.name}}</div>
    {{person.note}}
  </div>
</template>

<script>
export default {
  name: "LunchNotesWeekDayPerson",
  props: {
    person: {
      type: Object,
      required: true
    }
  }
};
</script>

<style>

.note__person {
  padding: 16px;
  white-space: pre-line;
}

.note__person:first-child {
  border-top: 1px solid #eaebef;
}
.note__name {
  padding: 0 0 3px 0;
  font-size: 12px;
  font-weight: 700;
  color: #9d9fa7;
}
</style>
