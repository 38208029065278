<template>
  <th class="lunch-list__row-spacing"></th>
</template>

<script>
export default {};
</script>

<style>
</style>
