<template>
  <div class="lunch-view-container">
    <div class="lunch-menu-top">
      <div
        class="lunch-menu-top__toggle"
        @click="isShowingNotes = !isShowingNotes"
      >
        {{ changeViewText }}
      </div>
      <div class="lunch-menu-top__refresh" @click="reloadPage">Refresh</div>
    </div>
    <keep-alive>
      <component
        :is="selectedView"
        :allWeekDates="allWeekDates"
        :employees="employees"
        :lunchTotals="lunchTotals"
      />
    </keep-alive>
  </div>
</template>

<script>
import addDays from "date-fns/addDays";
import startOfWeek from "date-fns/startOfWeek";
import { fetchPeople, getLunchRecords } from "@/api";

import LunchList from "@/components/Lunch/LunchList";
import LunchNotes from "@/components/Lunch/LunchNotes";
export default {
  name: "Lunch",

  components: {
    LunchList,
    LunchNotes
  },

  data() {
    return {
      isShowingNotes: false,
      employees: {},
      lunchRecords: []
    };
  },

  computed: {
    selectedView() {
      if (this.isShowingNotes) {
        return LunchNotes;
      } else {
        return LunchList;
      }
    },

    changeViewText() {
      if (this.isShowingNotes) return "Show list";
      else return "Snow notes";
    },

    allWeekDates() {
      let startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
      let currentWeek = this.createWeek(startDate);
      let week2Start = addDays(startDate, 7);
      let week2 = this.createWeek(week2Start);
      let week3Start = addDays(startDate, 14);
      let week3 = this.createWeek(week3Start);
      let week4Start = addDays(startDate, 21);
      let week4 = this.createWeek(week4Start);
      return {
        currentWeek,
        week2,
        week3,
        week4
      };
    },

    lunchTotals() {
      const office = this.$route.params.office;
      // Loop through all weeks and sum lunch records for that week
      let totals = {};
      for (let week of Object.keys(this.allWeekDates)) {
        let weekNumber = this.allWeekDates[week].weekNumber;
        let year = this.allWeekDates[week].year;
        totals[week] = {
          Monday: 0,
          Tuesday: 0,
          Wednesday: 0,
          Thursday: 0,
          Friday: 0,
          office,
          weekNumber,
          year,
          records: this.lunchRecords.filter(r => r.weekNumber === weekNumber)
        };

        for (let record of totals[week].records) {
          // Loop through each weekday and add to total count
          for (let weekDay of Object.keys(record.weekAmount)) {
            totals[week][weekDay] += parseInt(record.weekAmount[weekDay]);
          }
        }
      }
      return totals;
    }
  },

  methods: {
    reloadPage() {
      window.location.reload();
    },

    // Create an object where property names are the users email address (for easier lookup). We also set a "lunch records" property on each employee.
    // We later fetch lunch records from firebase (getLunchRecords) and insert them into this property.
    createEmployeesObject(employeeArr) {
      const office = this.$route.params.office;

      return new Promise(resolve => {
        let obj = {};
        employeeArr.forEach(currentItem => {
          this.$set(obj, currentItem.email.trim(), {
            ...currentItem,
            lunchRecords: [],
            office
          });
        });
        this.employees = obj;

        // Add extras (for lunch list)
        this.$set(this.employees, `extras-${office}`, {
          email: `extras-${office}`,
          firstName: "Extras",
          lunchRecords: [],
          office
        });
        resolve();
      });
    },

    // Loop through the lunch records that we've fetched from firebase. Put them on the employees-object on the corresponding user.
    setLunchRecordsOnEmployee(lunchRecords) {
      lunchRecords.forEach(item => {
        //this.employees[item.user].lunchRecords.push(item);
        if (this.employees[item.user]) {
          this.employees[item.user].lunchRecords = [
            ...this.employees[item.user].lunchRecords,
            item
          ];
        }

        // Also, set lunch records on data object
        this.lunchRecords = lunchRecords;
      });
    }
  },

  async created() {
    const office = this.$route.params.office;

    // Get employees from sanity
    const people = await fetchPeople(office);
    await this.createEmployeesObject(people);

    let allRecords = [];
    // Get lunch records from firebase
    for (let week of Object.keys(this.allWeekDates)) {
      let records = await getLunchRecords({
        office,
        week: this.allWeekDates[week]
      });
      allRecords = [...allRecords, ...records];
    }
    /* let records = await getLunchRecords({
      office,
      week: this.allWeekDates.currentWeek
    }); */

    this.setLunchRecordsOnEmployee(allRecords);
  }
};
</script>

<style>
.lunch-view-container {
  padding-top: 20px;
}

.lunch-menu-top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0 20px 40px 0;
}

.lunch-menu-top__toggle {
  display: block;
  margin: 0 32px;
  color: #279fad;
  text-decoration: underline;
  cursor: pointer;
}

.lunch-menu-top__refresh {
  display: block;
  width: auto;
  padding: 16px 24px;
  border: 4px solid #26c6da;
  background-color: #26c6da;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-family: "Graphik";
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
}
.lunch-menu-top__refresh:hover {
  background-color: #fff;
  color: #26c6da;
}
</style>
