/* eslint-disable no-prototype-builtins */
<template>
  <td class="lunch__amount" :style="{'background': bgColor}">
    <img v-show="isInOffice" src="../../assets/in_office_greeen.svg" width="16" class="in-office-icon">
    <input
      type="tel"
      class="lunch-list__input"
      maxlength="2"
      :value="inputValue"
      ref="input"
      @keyup.enter="handleSave"
      @focus="showFloater(true)"
      @blur="showFloater(false)"
      @input="setValue"
    />
  </td>
</template>

<script>
import { createLunchRecord, saveLunchRecord } from "@/api";
import isToday from "date-fns/isToday";

export default {
  props: {
    date: {
      type: Object,
      required: true
    },
    lunchRecord: {
      type: Object,
      required: true
    },
    person: {
      type: Object,
      required: true
    },
    week: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      amount: 0,
      currentLunchRecord: null
    };
  },

  computed: {
    isInOffice() {
      if (this.lunchRecord.inOffice) {
        return this.lunchRecord.inOffice[this.date.weekday]
      } else return false
    },

    bgColor() {
      let color = "#E3E1DF";
      if (
        this.date.weekday === "Monday" ||
        this.date.weekday === "Wednesday" ||
        this.date.weekday === "Friday"
      ) {
        color = "#EBE9E7";
      }
      if (isToday(this.date.date)) {
        color = "#b3d7e4";
      }

      return color;
    },

    inputValue() {
      if (this.lunchRecord.weekAmount) {
        return this.lunchRecord.weekAmount[this.date.weekday];
      } else return "";
    },

    isEmpty() {
      return Object.keys(this.lunchRecord).length === 0;
    }
  },

  methods: {
    setValue(e) {
      this.amount = parseInt(e.target.value);
    },

    showFloater(bool) {
      this.$emit("showFloater", bool);
    },

    setAmountFromLunchRecordsProp() {
      let correctWeek = this.week.weekNumber;
      let lunchRecord = this.lunchRecords.find(
        el => el.weekNumber === correctWeek && el.year === this.week.year
      );
      if (lunchRecord) {
        this.currentLunchRecord = lunchRecord;
        let weekday = this.date.weekday;
        this.amount = lunchRecord.weekAmount[weekday];
      }
    },

    async handleSave() {
      // If lunchRecord is empty, create a new record
      if (this.isEmpty) {
        let lunchRecord = createLunchRecord({
          office: this.person.office,
          user: this.person.email,
          weekNumber: this.week.weekNumber,
          year: this.week.year
        });
        lunchRecord.weekAmount[this.date.weekday] = this.amount;
        await saveLunchRecord(lunchRecord);
        // If lunch record already exists, just update amount
      } else {
        let newRecord = { ...this.lunchRecord };
        newRecord.weekAmount[this.date.weekday] = this.amount;

        let obj = {
          id: this.lunchRecord.id,

          weekAmount: {
            [this.date.weekday]: this.amount
          }
        };

        await saveLunchRecord(obj);
      }
      this.$refs.input.blur();
    }
  }
};
</script>

<style>
.lunch-list__input {
  width: 100%;
  height: 58px;
  padding: 8px;
  border: 2px solid transparent;
  background-color: transparent;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
.lunch-list__input:focus {
  border: 2px solid #000;
}
.row-cell-grey {
  background: #ebe9e7;
}

.in-office-icon {
  position: absolute;
  top: 35%;
  left: 8px;
}
</style>
